@import url("https://webfontworld.github.io/pretendard/Pretendard.css");

* {
  font-family: 'Pretendard', sans-serif;
}

.content-container {
  padding: 2rem;
}

.cursor {
  cursor: pointer;
}

.ant-typography {
  white-space: pre-wrap;
}

.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}